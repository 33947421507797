<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('ChatbotAnswers')"
    @cancel="onCancel('ChatbotAnswers')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'ChatbotAnswersCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'ChatbotAnswers',
      pageHeader: 'Создание ответа на вопросы для чат-бот',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Ответы на вопросы для чат-бота',
          route: { name: 'ChatbotAnswers' },
        },
        {
          text: 'Создание ответа на вопросы для чат-бот',
        },
      ],
      initialForm: {
        answerText: null,
        baseName: null,
      },
      form: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          form: [

					{
              type: 'text',
              key: 'baseName',
              label: 'База данных',
            },
            {
              type: 'textarea',
              key: 'answerText',
              label: 'Текст ответа',
            },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
    }),
  },
};
</script>
